






























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { nl as locale } from 'date-fns/locale';
import { Establishment, Period, PeriodType, ProductSalesKPI, DateRange } from '@/common';
import { parseISO, format, setMonth } from 'date-fns';
import Loading from '@/components/Loading.vue';
import productSalesKPIApi from '@/api/productSalesKPIApi';
import Sidebar from '@/components/Sidebar';
import EstablishmentSelect from '@/components/EstablishmentSelect.vue';
import PeriodPicker from '@/components/PeriodPicker.vue';
import EmployeeSelect from '@/components/EmployeeSelect.vue';
import { DataList, ProductList } from '@/components/ProductList';

const appModule = namespace('app');

interface Data {
  name: string;
  current: number;
  previous: number;
}

interface PersonalData {
  name: string;
  worked: boolean;
  kpi: Kpi[];
}

interface Kpi {
  name: string;
  value: number;
}

@Component({
  components: {
    Loading,
    Sidebar,
    EstablishmentSelect,
    PeriodPicker,
    EmployeeSelect,
    DataList,
  },
})
export default class ProductView extends Vue {
  @appModule.State('establishment')
  public establishment!: Establishment;

  @appModule.Getter('selectedRange')
  public selectedRange!: DateRange | null;

  @appModule.State('period')
  public period!: Period;

  public productListId: number | null = null;

  public salesProductDataset1: ProductSalesKPI[] = [];
  public salesProductDataset2: ProductSalesKPI[] = [];

  public loading = false;
  public csvLoading = false;

  public async mounted() {
    await this.loadData();
  }

  get title() {
    switch (this.period.type) {
      case PeriodType.YEAR:
        return `Productoverzicht: ${this.period.year}`;
      case PeriodType.MONTH:
        return `Productoverzicht: ${format(setMonth(new Date(), this.period.month), 'MMMM', {
          locale,
        })} ${this.period.year}`;
      case PeriodType.WEEK:
        return `Productoverzicht: week ${this.period.week} - ${this.period.year}`;
      case PeriodType.DAY:
        return `Productoverzicht: ${format(parseISO(this.period.date), 'dd-MM-yyyy')}`;
      default:
        return `Productoverzicht`;
    }
  }

  @Watch('selectedRange')
  public onPeriodChange() {
    this.loadData();
  }

  public selectFirstProductList(val: ProductList) {
    this.productListId = val.id;
    this.loadData();
  }

  public async loadData() {
    if (this.selectedRange === null || this.productListId === null) {
      return;
    }

    this.loading = true;

    const d1 = format(this.selectedRange.d1, 'yyyy-MM-dd');
    const d2 = format(this.selectedRange.d2, 'yyyy-MM-dd');
    const l = this.productListId;

    try {
      this.salesProductDataset1 = await productSalesKPIApi.get(this.establishment, d1, d2, l);
    } finally {
      this.loading = false;
    }
  }

  public async downloadCsv() {
    if (this.selectedRange === null || this.productListId === null) {
      return;
    }

    if (!this.salesProductDataset1.length || this.loading) {
      return;
    }

    this.csvLoading = true;

    const d1 = format(this.selectedRange.d1, 'yyyy-MM-dd');
    const d2 = format(this.selectedRange.d2, 'yyyy-MM-dd');
    const l = this.productListId;

    try {
      const csv = await productSalesKPIApi.getCsv(this.establishment, d1, d2, l);
      const dataUri = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));

      window.open(dataUri);
    } finally {
      this.csvLoading = false;
    }
  }
}
